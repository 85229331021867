<template>
    <div>
        <v-row>
            <v-btn :loading="loadingBackup" style="margin: 30px;" color="green" dark v-if="returnAbility('backup:run')" @click="genrateBackup" >
                    <v-icon size="20">mdi-plus</v-icon>
                    {{ $t('Generate Backup') }}
             </v-btn>
           
   

        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0" class="pt-5">
                    <base-table4 @re-fetch-data="fetchData" @re-fetch-paginated-data="fetchData($event)"
                        :tableOptions="tableOptions" :enableDelete="true">
                    </base-table4>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import axios from 'axios';
import baseTable4 from '../../../../../components/base/baseTable4.vue'


export default {
    components: {
        baseTable4,

    },
    data() {
        return {
            loadingBackup: false,
            tableOptions: {
                tableHeaders: [
                    'id',
                    'name',
                    'date',
                    'settings'
                ],
                tableData: [],
                loading: false,
                tableMeta: {
                    total: 10,
                    page: 1,
                },
            },
            dialog: false,
            submitBtnLoading: false,
            name: "",
            filters:{
                name: null,
                price:  null,

            },
        };
    },
    methods: {
       async genrateBackup() {
            try {
                this.loadingBackup = true;
                const res = await axios.post("backup/run");
                this.$Notifications(this.$t("Backup was created successfully"), { rtl: true, timeout: 1000 },
            "success"
          );
                this.loadingBackup = false;
                this.fetchData();
            } catch (error) {
                console.log(error);
            }
        },
       async fetchData() {
            try {
                const res = await axios.get("backup");
                this.tableOptions.tableData = res.data.data;
                // this.$Notifications(this.$t("Backup was created successfully"));
            } catch (error) {
                console.log(error);
            }
        }
    },
    computed: {
      
    },
    created() {
        this.fetchData();
    }



};
</script>